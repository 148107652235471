@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease, fadeOut 3s ease 4s forwards; /* Fades in quickly and fades out slowly after 4 seconds */
    z-index: 1000; /* Ensures splash is above other content */
  }
  
  .logo-animate {
    width: 250px; /* Adjust as needed */
    animation: rotate 4s linear infinite;
    /* opacity: 0.7; */
  }
  