/* src/TopRow.css */
.top-row {
  background-color: rgb(235, 240, 244);
  color: rgb(21, 21, 21);
  padding: 0.07rem .2rem; /* Reduced vertical padding */
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 100vw;
  font-family: Helvetica, Arial, sans-serif;
  flex-wrap: wrap; /* Allows elements to wrap to the next line */
}

.usc-logo {
  max-height: 40px;
  width: auto; /* Maintain aspect ratio */
  margin-left: auto; /* Optional: add some space between logo and title */
  margin-right: 10px; /* Optional: add some space between logo and title */
}

.right-section {
  display: flex; /* Align the link and button in a row */
  align-items: center;
}

.toggle-docs-btn, .download-link {
  background-color: transparent; /* No background color */
  color: black; /* White text */
  border: none; /* No borders */
  padding: 10px 20px; /* Padding for clickable area */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor changes to pointer on hover */
  font-size: 16px;
  margin-left: 10px; /* Adds space between link and button */
  margin-right: 10px; /* Adds space between link and button */
  text-decoration: none; /* Remove underline from link */
  display: inline-block; /* Align link with the button */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

#toggle-docs-btn-id{
  margin-right: 20px;
}

.toggle-docs-btn:hover, .download-link:hover {
  background-color: #ffffff; /* Slight background on hover for feedback */
  color: black; /* Change text color on hover */}

.top-row h2 {
  text-align: left;
  padding-left: 20px;
  font-size: 1.7rem;
  margin-right: 10px;
}

.main {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}



.button-container {
  display: flex; /* Makes the buttons align horizontally */
  align-items: center; /* Aligns buttons vertically */
  margin-bottom: 10px; /* Adds some space below the buttons when they wrap */
}

.buttons-and-logo {
  display: flex; /* Aligns buttons and logo horizontally */
  align-items: center; /* Ensures vertical alignment */
  flex-wrap: wrap; /* Allows these elements to wrap */
}

@media (max-width: 600px) {
  .top-row {
      display: flex;
      flex-direction: column; /* Stack elements vertically */
      align-items: center; /* Center-align items */
      padding: 1rem; /* Add padding for overall spacing */
  }

  .top-row h2 {
    margin-bottom: 15px; /* Add space below the title */
  }

  .buttons-and-logo {
      width: 100%; /* Use full width to space out items */
      display: flex;
      flex-direction: column; /* Stack logo and buttons vertically */
      align-items: center; /* Center-align the logo and buttons */
  }

  .button-container {
      margin-bottom: 15px; /* Space between buttons and logo */
  }

  .usc-logo-link img {
      width: 160px; /* Adjust logo size for mobile */
      margin-bottom: 10px; /* Space below logo */
  }

  .toggle-docs-btn {
    font-size: 12px; /* Adjust button font size */
    padding: 8px 12px; /* Smaller padding for buttons */
  }
}
