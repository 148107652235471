.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
  min-height: 100vh; /* Ensure the main container takes at least full viewport height */
  position: relative; /* For footer positioning */
  padding-bottom: 30px; /*Space for footer, adjust as needed*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img-responsive {
  max-width: 100%;
  object-fit: contain;
  max-height: 80vh; 
}


.controls {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: center; /* Centers items horizontally */
  flex-wrap: wrap; /* Allows items to wrap to next line if space runs out */
  gap: 10px; /* Adds space between items */
}

.controls2 {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: center; /* Centers items horizontally */
  flex-wrap: wrap; /* Allows items to wrap to next line if space runs out */
  gap: 10px; /* Adds space between items */
}

/* Style for the form */
.upload-form {
  text-align: center;
  /* margin-left: 200px; */

  /* margin-bottom: 40px; */
}

/* Style for the file input */
.upload-form input[type="file"] {
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.upload-form input[type="text"] {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

/* Style for the upload button */
.upload-form button {
  padding: 10px 15px;
  background-color: #4969da;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 10px;
}

.upload-form button:hover {
  background-color: rgb(14, 58, 217);
}

.upload-form button:active {
  transform: scale(0.95);
}

.controls button {
  padding: 10px 15px; /* Adjust padding for size */
  background-color: #4969da;
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem; /* Adjust font size as needed */
  cursor: pointer; /* Cursor changes to a hand symbol when hovered */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.controls button:hover {
  background-color: rgb(14, 58, 217);
}

.controls button:active {
  transform: scale(0.95); /* Slightly scale down the button when clicked */
}

.controls label {
  margin-right: 10px; /* Space between label and slider */
}

/* Additional styling for the rotation slider if needed */
.controls input[type=range] {
  cursor: pointer;
}

.controls{
  margin-bottom: 40px;
}

.image-and-legend-container {
  display: flex;
  justify-content: center; /* Centers children horizontally */
  align-items: flex-start; /* Aligns children at the top */
  width: 100%; /* Full width of its parent */
  max-width: 90%; /* Increase this to allow more space */
  margin: 0 auto; /* Auto margin for horizontal centering */
}

.image-container {
  flex: 1; /* Takes up as much space as possible */
  flex-grow: 1; /* Allows it to grow and take necessary space */
  position: relative; /* For absolute positioning of TransformWrapper */
}

.img-and-text-container {
  display: block; /* Ensures vertical layout */
  width: 100%; /* Container takes full width of its parent */
  max-width: 80%; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the container */
}

.img-legend {
  max-height: 40vh; /* Increase the maximum height */
  width: auto; /* Adjust width to auto */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensures the image doesn't inline with other elements */
  margin-bottom: 10px; /* Space between image and text */
}

.legend-text {
  text-align: left;
  width: 100%; /* Allows the text to take up the full width of its container */
}

.app-footer {
  background-color: rgb(235, 240, 244);
  color: rgb(21, 21, 21);
  text-align: center;
  padding: 5px 0; /* Reduced vertical padding */
  margin-top: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 0.9rem; /* Slightly smaller font size */
  height: auto;
  box-sizing: border-box;
}

.options-dropdown {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: white;
  color: #333;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.options-dropdown:focus {
  outline: none;
  border-color: #4CAF50; /* Highlight color when focused */
}

.pad-label{
  margin-right: 10px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed; /* To overlay on top of other content */
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  z-index: 1000; /* Ensure it's above other content */
}

.spinner {
  border: 5px solid #f3f3f3; /* Light grey border */
  border-top: 5px solid #3498db; /* Blue border for spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.download-link {
  display: inline-block;
  padding: 10px 15px;
  margin: 10px 10px;
  background-color: #616662;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
}

.download-link:hover {
  background-color: #45a049;
}

/* Dropdown Button */
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 6px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #3e8e41;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Style the dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

.app-footer p a {
  color: inherit; /* This will make the color the same as the parent element */
}



/* .advanced-settings {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
} */

.advanced-settings {
  display: flex;
  flex-direction: row; /* Aligns children in a row */
  /* justify-content: space-around;  */
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start; /* Aligns children at the top */
  gap: 20px; /* Adjust this value for spacing between each settings container */
}

.slider-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the start (left) */
  gap: 5px; /* Adjust gap between label and inputs */
  margin-bottom: 20px; /* Space below each slider container */
}

.inputs-container {
  display: flex;
  align-items: center; /* Align inputs vertically in the center */
  gap: 10px; /* Space between range and number inputs */
}

/* Adjust label styles for better readability */
.slider-container label {
  /* font-weight: bold; */
  margin-bottom: 5px; /* Small space between label and inputs */
}

/* Input specific styles remain largely unchanged, ensuring they are well-presented */
.slider-container input[type="range"],
.slider-container input[type="number"] {
  width: auto; /* Allow inputs to occupy necessary width */
  /* Other styles like padding, border, and text-align remain as previously defined */
}





/* .color-container {
  display: flex;
  align-items: center;
  gap: 5px;
} */



/* .number-settings-container {
  display: flex;
  align-items: center;
} */

/* .checkbox-label {
  margin-right: 10px;
} */

.slider-group {
  display: block; /* Each slider group takes up its own line */
  margin-bottom: 10px; /* Space between each slider group */
  text-align: center; /* Centers the content within each slider group */
}

.number-settings-container {
  background-color: #f0f0f0; /* Light grey background */
  padding: 10px 20px; /* Added horizontal padding */
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-block; /* Makes the container only as wide as its content */
}

.number-settings-header h3 {
  margin: 0;
  color: #333; /* Dark text color for the header */
}

.number-settings-header {
  margin-bottom: 20px; /* Space between header and sliders */
  text-align: center; /* Centers the header text */
}

/* .number-settings-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
} */

.checkbox-label {
  margin-right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.merge-check {
  margin-right: 20px;
}

#merge-checkbox {
  margin-left: 8px;

}

.nucleotide-settings-container {
  background-color: #f0f0f0; /* Light grey background */
  padding: 10px 20px; /* Added horizontal padding */
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-block; /* Makes the container only as wide as its content */
  width: auto; /* Auto width for the minimum necessary width */
}

.nucleotide-settings-header {
  margin-bottom: 20px; /* Space between header and sliders */
  text-align: center; /* Centers the header text */
}

.nucleotide-settings-header h3 {
  margin: 0;
  color: #333;
}

/* .nucleotide-settings-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
} */

#color-picker-div {
  display: flex;
  flex-direction: column; /* Align children vertically in a column */
  align-items: center; /* Center children horizontally */
  gap: 10px; /* Space between each color container */
}

/* Styles for Color Settings Section */
.color-settings-container {
  background-color: #f0f0f0; /* Light grey background */
  padding: 10px; /* Add padding inside the container */
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%; /* Increase the width to 90% */
  margin-left: auto; /* Centers the container */
  margin-right: auto; /* Centers the container */
}

.color-container label {
  white-space: nowrap; /* Prevent label from wrapping */
}

.color-settings-header h3 {
  margin: 0;
  color: #333; /* Dark text color for the header */
}

.color-settings-header {
  margin-bottom: 10px; /* Space between header and sliders */
  text-align: center; /* Centers the header text */
}

.color-settings-content {
  display: flex;
  flex-direction: column; /* Align items in a column */
  align-items: flex-end; /* Center items vertically */
  gap: 10px; /* Space between items */
  padding: 10px; /* Horizontal padding inside the container */
}

.color-container {
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Vertically align items within each color container */
  gap: 10px; /* Space between label and input */
  padding: 5px 0; /* Vertical padding inside each color container */
  justify-content: flex-end;
}

.color-container input[type="color"],
.color-container input[type="text"] {
  align-self: center; /* Align inputs in the center vertically */
  width: 80px; /* Adjust width to make it smaller */
}

.nucleotide-settings-content{
  display: block; /* Ensures each option is on its own line */
  margin-bottom: 20px; /* Increase space between each option */
  text-align: center;
}

.label-left-align {
  text-align: left;
}

.select-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  gap: 10px; /* Adjust this value for spacing between label and select dropdown */
  margin-bottom: 10px;
}

#small-text {
  width: 120px;
  padding: 8px;
}

.beige-button {
  background-color: #f1f1f1 !important;
  color: black !important;
}

.beige-button:hover{
  background-color: #d0d3d7 !important;
}

#run-on-ex-data{
  margin-left: 0px;
}

#row-2{
  margin-bottom: 10px;
}

.same-line-div {
  display: flex; /* Aligns children in a row */
  justify-content: center; /* Spaces out the children evenly */
  align-items: center; /* Aligns items vertically in the center */
  gap: 20px;
}

.input-group label, .checkbox-label {
  display: flex;
  align-items: center; /* Ensures the text and input align vertically */
  /* margin-right: 10px;  */
}

.input-group input[type="file"], .checkbox-label input[type="checkbox"] {
  margin-left: 10px; /* Adds some spacing between the label text and the input/checkbox */
  cursor: pointer; /* Cursor changes to pointer on hover */
}

@media (max-width: 600px) { /* Adjust 600px based on your breakpoint */
  .same-line-div {
    display: block; /* Stacks the input groups vertically on small screens */
  }

  .input-group {
    width: 100%; /* Ensures the container takes full width */
    text-align: center; /* Centers the content within the container */
  }
  
  .input-group label{
      display: block; /* Forces each element to take a full width */
      width: 100%; /* Sets the width to full container width */
      margin-bottom: 10px; /* Adds space below each element */
  }
  .input-group input[type="file"]{
    display: block; /* Forces each element to take a full width */  
    width: 60%; /* Adjusts width to account for padding/margins */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px; /* Adds space below each element */
  }

  .input-group input{
    display: block; /* Forces each element to take a full width */  
    width: 60%; /* Adjusts width to account for padding/margins */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px; /* Adds space below each element */
    }
  #small-text {
    width: 50%;
    display: block; /* Forces each element to take a full width */
    margin-bottom: 10px; /* Adds space below each element */
    margin-left: auto;
    margin-right: auto;
  }
  .app-footer {
    font-size: 0.8rem; /* Slightly smaller font size on small screens */
    padding: 15px 10px; /* Increase padding for better readability */
    position: static; /* Static position so it doesn't overlap content */
  }

}

.transform-wrapper-container {
  display: flex;
  justify-content: center; /* Center the children horizontally */
  align-items: center; /* Center the children vertically */
  flex-wrap: wrap; /* Allow the children to wrap onto multiple lines if needed */
  /* margin-bottom: 10px; */
}
.nucleotide-settings-content, .slider-container, .inputs-container {
  text-align: left; /* Align text to the left */
}

#nuc-settings-bp-dropdown{
  margin-left: 0px  !important;
}

.inputs-container{
  margin-left: 0px !important;
}

.checkbox-label {
  margin-bottom: 10px; /* Adds space below the checkbox label before the next content */
}

.nucleotide-settings-container .button-container {
  display: flex;
  justify-content: center; /* Center button horizontally */
  width: 100%; /* Take up full width to center correctly */
}

#num-label-size-label{
  padding-top: 10px !important;
}

#processing-div{
  margin-bottom: 10px;
}

/* Styling buttons */
.controls button {
  font-size: .85rem; /* Adjust the font size as needed */
}

/* Styling the rotate label */
.controls label {
  font-size: .85rem; /* Adjust the font size as needed */
}

/* Styling the number input */
.controls input[type="number"] {
  font-size: .85rem; /* Adjust the font size as needed */
}

.controls .dropdown-content {
  font-size: .85rem; /* Adjust the font size as needed */
}

/* Styling buttons */
.controls2 button {
  font-size: .85rem; /* Adjust the font size as needed */
}

/* Styling the rotate label */
.controls2 label {
  font-size: .85rem; /* Adjust the font size as needed */
}

/* Styling the number input */
.controls2 input[type="number"] {
  font-size: .85rem; /* Adjust the font size as needed */
}

.controls2 .dropdown-content {
  font-size: .85rem; /* Adjust the font size as needed */
}

.content-container {
  display: flex;
  justify-content: space-around; /* Adjust spacing between the viewer and image sections */
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  gap: 20px; /* Adds some space between the sections when they wrap */
}


.viewer-section, .image-column {
  flex: 1; /* Allow each section to fill the space evenly */
  min-width: 300px; /* Prevent sections from becoming too narrow */
  width: 45%; /* Initial width setting, adjust if necessary */
}

.image-section {
  flex: 1; /* Each section flexibly fills the space available */
}

.section-header {
  text-align: center;
  margin-bottom: 10px; /* Space below headers */
}


.responsive-small-img {
  width: 100%; /* Fill the width of the container */
  height: auto; /* Maintain aspect ratio */
  max-height: 30vh; /* Maximum height relative to the viewport */
  object-fit: contain; /* Ensures the image is scaled to maintain its aspect ratio while fitting within the element’s content box */
}

.responsive-img {
  width: 100%; /* Fill the width of the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures the image is scaled to maintain its aspect ratio while fitting within the element’s content box */
}

.vert-container {
  display: flex;
  flex-direction: column; /* Align children vertically */
  align-items: center; /* Center children horizontally within the container */
  justify-content: center; /* Align children vertically within the container */
  height: auto; /* Allow the container height to adjust to its content */
  margin-bottom: 20px; /* Space below each container */
}

#pymol-btn{
  margin-top: 10px;
}
#heavy-img{
  cursor: pointer; /* Changes cursor to pointer on hover */
}
#heavy-img:hover {
  opacity: 0.8; /* Slightly fade the image to indicate hover */
}

/* LogDisplay.css */
.log-container {
  font-family: 'Courier New', Courier, monospace; /* Monospace font for log display */
  white-space: pre-wrap; /* Preserve whitespaces and newlines */
  width: 60%; /* Log container takes up 60% of the page width */
  margin: auto; /* Center the log container horizontally */
  text-align: left; /* Ensure text is left-aligned */
  overflow-wrap: break-word; /* Ensure long lines wrap to the next line */
  background-color: #f4f4f4; /* Light grey background for the log area */
  border-radius: 8px; /* Rounded corners for the log container */
  padding: 20px; /* Padding inside the log container */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for modern appearance */
  line-height: 1.5; /* Increase line height for better readability */
  margin-top: 20px; /* Space above the log container */
  color: #333; /* Dark grey color for text, easier on the eyes than pure black */
}

.log-header {
  font-weight: bold; /* Make header bold */
  margin-bottom: 10px; /* Space below the header */
}

/* Headers and text styling */
.header-container h2, .header-container h3 {
  margin: 10px 0;  /* Spacing around headers */
  color: #2a2a2a;  /* Dark grey for better readability */
}

.header-container h3 {
  font-size: 1.2em;  /* Slightly smaller than h2 */
  font-weight: normal;  /* Normal weight for model description */
}

.ip-message {
  font-style: italic;  /* Italicize IP message for distinction */
  color: #666;  /* Lighter text color for less emphasis */
  margin-bottom: 20px;  /* Space before the log section */
}

/* Base styles for the form elements */
.upload-form .same-line-div {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  gap: 10px; /* Space between items */
  align-items: center; /* Align items vertically */
}

.input-group,
.options-dropdown,
.checkbox-label {
  margin-bottom: 10px; /* Add some space below each item */
}

/* Specific media query for mobile devices */
@media (max-width: 600px) {
  .upload-form .same-line-div {
    flex-direction: column; /* Stack elements vertically on small screens */
  }

  .input-group, .options-dropdown, .checkbox-label, button {
    width: 90%; /* Full width for mobile devices */
    align-items: center; /* Align checkbox and label text */
  }
}

.margTop{
  margin-top: 30px !important; 
}