/* Faq.css */
.faq-wrapper {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .faq-content {
    margin: 20px;
    text-align: left;
  }
  
  .faq-content h1 {
    color: #2A2A2A;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq-item h2 {
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.4em;
  }
  
  .faq-item p {
    font-size: 1em;
    line-height: 1.6;
    text-align: justify;
    margin-bottom: 20px;
    color: #666;
  }
  
  .faq-item a {
    color: #0056b3;
    text-decoration: none;
  }
  
  .faq-item a:hover {
    text-decoration: underline;
  }
  
  .faq-item ol {
    padding-left: 20px;  /* Proper indentation for lists */
    text-align: left;  /* Ensures list content is also left-aligned */
  }
  
  .faq-item li {
    margin-bottom: 5px;  /* Space between list items */
    text-align: left;  /* Ensures list items text alignment */
  }

  .faq-item ul {
    margin-top: 5px;
  }