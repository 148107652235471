.image-row {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .image {
    width: 15%; /* Adjust based on desired size */
    height: auto; /* Adjust height automatically to maintain aspect ratio */
    object-fit: contain; /* Cover the container box while keeping aspect ratio */
    opacity: 0.5;
    transition: transform 0.3s ease, opacity 0.3s ease;
    cursor: pointer;
  }
  
  .image:hover {
    opacity: 1; /* Full opacity on hover */
    transform: scale(1.1); /* Grow the image slightly on hover */
  }
  